<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 black" style="color: white">
          {{ $t("files.title") }}
        </v-card-title>

        <v-card-text class="pt-4" style="font-size: 1.3rem">
          <v-list>
            <v-list-item v-for="(f, i) in files" :key="i">
              <v-list-item-title>{{ f.fileName }}</v-list-item-title>
              <v-list-item-action>
                <v-btn icon color="blue" @click="download(f.id)"
                  ><v-icon>mdi-tray-arrow-down</v-icon></v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="$emit('close')"
            style="font-size: 1.1rem; letter-spacing: 0px"
          >
            {{ $t("files.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
export default {
  props: ["dialog", "files"],
  methods: {
    download(file) {
      this.$emit("download", file);
    },
  },
};
</script>